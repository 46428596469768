import { render, staticRenderFns } from "./AdminModuleSubscriptionsIndex.vue?vue&type=template&id=4890c508&"
import script from "./AdminModuleSubscriptionsIndex.vue?vue&type=script&lang=ts&"
export * from "./AdminModuleSubscriptionsIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4890c508')) {
      api.createRecord('4890c508', component.options)
    } else {
      api.reload('4890c508', component.options)
    }
    module.hot.accept("./AdminModuleSubscriptionsIndex.vue?vue&type=template&id=4890c508&", function () {
      api.rerender('4890c508', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/moduleSubscriptions/AdminModuleSubscriptionsIndex.vue"
export default component.exports